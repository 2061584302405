<template>
  <div class="row justify-content-md-center mt2000">
    <div class="col-lg-4 col-md-5 col-12">
      <div class="card">
        <div class="card-header">
          <h4>Profile</h4>
        </div>
        <div class="card-body">
          <form v-on:submit.prevent="fnUpdateUserProfile">
            <div class="form-group">
              <label for="firstName">First name</label>
              <input
                type="text"
                class="form-control"
                id="firstName"
                placeholder="First name"
                v-model="userData.firstName"
              />
              <div class="invalid-feedback" v-if="errors.firstName">
                {{ errors.firstName[0] }}
              </div>
            </div>
            <div class="form-group">
              <label for="middleName">Middle name</label>
              <input
                type="text"
                class="form-control"
                id="middleName"
                placeholder="Middle name"
                v-model="userData.middleName"
              />
            </div>
            <div class="form-group">
              <label for="lastName">Last name</label>
              <input
                type="text"
                class="form-control"
                id="lastName"
                placeholder="Last name"
                v-model="userData.lastName"
              />
              <div class="invalid-feedback" v-if="errors.lastName">
                {{ errors.lastName[0] }}
              </div>
            </div>
            <div class="form-group">
              <label for="role">Role</label>
              <select
                class="form-control"
                id="userRole"
                disabled
                v-model="userData.role_id"
              >
                <option value="1">Admin</option>
                <option value="2">Staff</option>
              </select>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Email"
                disabled
                v-model="userData.email"
              />
              <div class="invalid-feedback" v-if="errors.email">
                {{ errors.email[0] }}
              </div>
            </div>
            <div class="text-right">
              <button
                type="button"
                class="btn btn-secondary btn-sm mr-2"
                @click="fnCalcel()"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-success btn-sm">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  required,
  email,
  numeric,
  minValue,
  minLength,
  sameAs,
  requiredUnless,
  between,
} from "vuelidate/lib/validators";
import * as messageService from "../../services/message.service";
import * as authService from "../../services/auth.service";
export default {
  name: "profileUpdate",
  components: {},
  data() {
    return {
      errors: {},
      userData: {
        id: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        profileImage: "",
        role_id: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getUserProfile"]),
  },
  methods: {
    fnUpdateUserProfile: async function () {
      let formData = new FormData();
      formData.append("firstName", this.userData.firstName);
      formData.append("middleName", this.userData.middleName);
      formData.append("lastName", this.userData.lastName);
      formData.append("email", this.userData.email);

      try {
        const response = await authService.profileUpdate(formData);
        messageService.fnSweetAlertSuccessToast(
          "Profile",
          "Updated successfully"
        );

        this.$router.push({ name: "login" });
      } catch (error) {
        switch (error.response.status) {
          case 401:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Profile update",
              error.response.data.message
            );
            break;
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Profile update",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Profile update",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Profile update",
              "Something wrong, please try again!"
            );
            break;
        }
      }

      this.userData = {
        id: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        profileImage: "",
        role_id: "",
      };
    },
    fnCalcel() {
      if (
        this.getUserProfile.role_id == 1 ||
        this.getUserProfile.role_id == 2
      ) {
        this.$router.push({ name: "dashboardAdmin" });
      } else if (this.getUserProfile.role_id == 3) {
        this.$router.push({ name: "dashboardDealer" });
      }
    },
  },
  watch: {
    getUserProfile: function (val) {
      this.userData = val;
    },
  },
  created() {
    this.userData = this.getUserProfile;
  },
};
</script>
